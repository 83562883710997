<template>
  <v-container>
    <v-row align="center">
      <v-col cols="12">
        <v-row justify="center" align="center" no-gutters>
          <v-alert color="red lighten-1" type="error" v-if="alerts" dense>
            {{ alerts }}
          </v-alert>

          <v-alert color="green lighten-1" type="success" v-if="messages" dense>
            {{ messages }}
          </v-alert>
        </v-row>
      </v-col>

      <v-col cols="12">
        <v-alert v-if="grade_completed" color="green lighten-1" dense text>
          <v-row>
            <v-col class="shrink">
              <v-switch
                v-model="viewAllStudents"
                color="primary"
                class="mt-2"
                hide-details
              ></v-switch>
            </v-col>
            <v-col class="grow">
              您已经完成评分，可以切换查看所有学生资料
              <br />
              You have completed all grade, and thus able to view all students
            </v-col>
          </v-row>
        </v-alert>
        <v-card v-if="viewAllStudents">
          <v-card-text>
            <v-data-table
              :headers="infoHeaders"
              :items="all_students"
              :search="search"
              @click:row="showCampDetail"
              class="table-cursor"
              sort-by="university"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title> 夏令营资料/Camp Info List</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-toolbar>
                <v-row class="ma-2" dense align="center" justify="center">
                  <v-col>
                    <v-autocomplete
                      label="筛选状态/Filter Status"
                      v-model="showStatuses"
                      multiple
                      :items="infoStatusSelect"
                      item-value="name"
                    >
                      <template v-slot:item="{ item, on, attrs }">
                        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                          <v-list-item-action>
                            <v-checkbox :input-value="active"></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-icon>{{ infoStatusList[item.name].icon }}</v-icon
                              >{{ item.desc }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{ item }">
                        <v-chip
                          close
                          @click:close="showStatuses = showStatuses.filter((s) => s != item.name)"
                          ><v-icon>{{ infoStatusList[item.name].icon }}</v-icon
                          >{{ item.desc }}</v-chip
                        >
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </template>

              <template v-slot:[`item.status`]="{ item }">
                <v-icon>{{ infoStatusList[item.status].icon }}</v-icon
                >{{ infoStatusList[item.status].desc }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>

        <v-card v-else>
          <v-card-title>夏令营资料评分/Camp Grade</v-card-title>
          <v-card-text>
            <v-data-table
              :headers="gradeHeaders"
              :items="group_students"
              @click:row="showCampDetail"
              class="table-cursor"
              sort-by="university"
              hide-default-footer
              disable-pagination
            >
              <template v-slot:top>
                <v-alert color="info" dense text>
                  <h5>提示</h5>
                  <div class="text-caption">
                    90-100分（强烈推荐入营），80-89（推荐入营），60-79（不建议入营）
                  </div>
                  <v-divider class="my-2 info" style="opacity: 0.22"></v-divider>
                  <h5>NOTE</h5>
                  <div class="text-caption">
                    90-100（strongly recommended），80-89（recommended），60-79（not recommended）
                  </div>
                </v-alert>
              </template>

              <v-spacer></v-spacer>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
.table-cursor table tbody tr:hover {
  cursor: pointer;
}
</style>

<script>
import requests from "@/http";

export default {
  data: () => ({
    alerts: "",
    messages: "",
    search: "",
    gradeHeaders: [
      { text: "姓名/Name", value: "name" },
      { text: "学校/University", value: "university" },
      { text: "院系/Department", value: "department" },
      { text: "评分/Grade", value: "grade" },
    ],
    showStatuses: ["submitted"],
    infoStatusList: {
      saved: { desc: "已保存/saved", icon: "mdi-content-save" },
      returned: { desc: "已退回/returned", icon: "mdi-send" },
      submitted: { desc: "已提交/submitted", icon: "mdi-account-check" },
      passed: { desc: "已通过/passed", icon: "mdi-flag" },
      failed: { desc: "未通过/failed", icon: "mdi-delete-circle" },
    },
    infoStatusSelect: [
      { desc: "已保存/saved", name: "saved" },
      { desc: "已退回/returned", name: "returned" },
      { desc: "已提交/submitted", name: "submitted" },
      { desc: "已通过/passed", name: "passed" },
      { desc: "未通过/failed", name: "failed" },
    ],
    viewAllStudents: false,
    group_students: [],
    all_students: [],
    grade_completed: false,
  }),
  created() {
    this.getCampInfoList();
  },
  computed: {
    infoHeaders() {
      return [
        { text: "姓名/Name", value: "name" },
        { text: "学校/University", value: "university" },
        { text: "院系/Department", value: "department" },
        {
          text: "状态/Status",
          value: "status",
          width: "180px",
          filter: (value) => this.showStatuses.includes(value),
        },
      ];
    },
  },
  methods: {
    getCampInfoList() {
      requests
        .get(`/api/review/grade`)
        .then((res) => {
          this.group_students = res.data.group_students;
          this.grade_completed = res.data.completed;
          this.all_students = res.data.all_students;
          if (this.grade_completed) {
            this.viewAllStudents = true;
          }
          for (let i = 0; i < this.all_students.length; i++) {
            if (this.all_students[i].status === "passed") {
              this.showStatuses = ["passed"];
              break;
            }
          }
        })
        .catch((err) => {
          if (err.response.status == 404) {
            this.alerts = err.response.data.detail;
          }
        });
    },
    showCampDetail(item) {
      // window.open(`/review/detail/${item.camp_id}`, "_blank").focus();
      this.$router.push(`/review/detail/${item.camp_id}`);
    },
  },
};
</script>
